import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axiosInstance from '../axiosInstance';
import './AvailabilityForm.css';

function AvailabilityForm() {
  // Initialize formData state with values from localStorage or default values
  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem('formData');
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          startDate: '',
          endDate: '',
          duration: '60',
          nothingBefore: '09:00',
          nothingAfter: '20:00',
          bufferTime: '15',
        };
  });
  const [textToCopy, setTextToCopy] = useState('');
  // Initialize excludeAllDayEvents from localStorage or default to false
  const [excludeAllDayEvents, setExcludeAllDayEvents] = useState(() => {
    const saved = localStorage.getItem('excludeAllDayEvents');
    return saved ? JSON.parse(saved) : false;
  });

  // Initialize includeDates from localStorage or default to false
  const [includeDates, setIncludeDates] = useState(() => {
    const saved = localStorage.getItem('includeDates');
    return saved ? JSON.parse(saved) : false;
  });

  const [availability, setAvailability] = useState(null);

  const [copied, setCopied] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState(() => {
    const saved = localStorage.getItem('selectedCalendars');
    return saved ? JSON.parse(saved) : [];
  });

  const [showCalendars, setShowCalendars] = useState(true);

  // New state variable for selectedDays
  const [selectedDays, setSelectedDays] = useState(() => {
    const saved = localStorage.getItem('selectedDays');
    return saved
      ? JSON.parse(saved)
      : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']; // All days selected by default
  });

  const [includeTimezone, setIncludeTimezone] = useState(() => {
    const saved = localStorage.getItem('includeTimezone');
    return saved ? JSON.parse(saved) : false;
  });

  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    const nextWeekStr = nextWeek.toISOString().split('T')[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: prevFormData.startDate || today,
      endDate: prevFormData.endDate || nextWeekStr,
    }));
  }, []);

  // Fetch calendars without manually setting headers
  useEffect(() => {
    axiosInstance
      .get('/get-calendars')
      .then((response) => {
        setCalendars(response.data);
        console.log(response.data);

        const fetchedCalendarIds = response.data.map((cal) => cal.id);

        // Reconcile selectedCalendars with fetchedCalendarIds
        setSelectedCalendars((prevSelectedCalendars) => {
          if (prevSelectedCalendars.length === 0) {
            // No saved preferences, select all calendars
            return fetchedCalendarIds;
          } else {
            // Keep only the calendars that exist in both
            const updatedSelectedCalendars = prevSelectedCalendars.filter((id) =>
              fetchedCalendarIds.includes(id)
            );
            return updatedSelectedCalendars;
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching calendars:', error);
        if (error.response && error.response.status === 401) {
          alert('Session expired. Please log in again.');
        }
      });
  }, []);

  // Save selected calendars to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedCalendars', JSON.stringify(selectedCalendars));
  }, [selectedCalendars]);

  // Save selectedDays to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedDays', JSON.stringify(selectedDays));
  }, [selectedDays]);

  // Save formData to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  // Save excludeAllDayEvents to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('excludeAllDayEvents', JSON.stringify(excludeAllDayEvents));
  }, [excludeAllDayEvents]);

  // Save includeDates to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('includeDates', JSON.stringify(includeDates));
  }, [includeDates]);

  useEffect(() => {
    localStorage.setItem('includeTimezone', JSON.stringify(includeTimezone));
  }, [includeTimezone]);
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCalendarChange = (e) => {
    const calendarId = e.target.value;
    let updatedSelectedCalendars;

    if (e.target.checked) {
      updatedSelectedCalendars = [...selectedCalendars, calendarId];
    } else {
      updatedSelectedCalendars = selectedCalendars.filter((id) => id !== calendarId);
    }

    setSelectedCalendars(updatedSelectedCalendars);
  };

  const getAvailability = async () => {
    if (selectedCalendars.length === 0) {
      alert('Please select at least one calendar.');
      return;
    }
    if (selectedDays.length === 0) {
      alert('Please select at least one day of the week.');
      return;
    }
    try {
      setLoading(true);
      const response = await axiosInstance.post('/get-availability', {
        formData: {
          ...formData,
          selectedCalendars,
          excludeAllDayEvents,
          selectedDays,
        },
      });
      setAvailability(response.data);
    } catch (error) {
      console.error('Error fetching availability:', error);
      if (error.response && error.response.status === 401) {
        alert('Session expired. Please log in again.');
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (availability) {
      let newText = '';
      if (includeDates) {
        newText += includeTimezone
          ? availability.formatted_slots_with_dates_with_tz.join('\n')
          : availability.formatted_slots_with_dates.join('\n');
      } else {
        newText += includeTimezone
          ? availability.formatted_slots_with_tz.join('\n')
          : availability.formatted_slots.join('\n');
      }
      setTextToCopy(newText);
    }
  }, [availability, includeDates, includeTimezone]);

  return (
    <div className="availability-container">
      {/* Calendar Selection Section */}
      <div className="calendar-selection">
        <div className="calendar-dropdown-header" onClick={() => setShowCalendars(!showCalendars)}>
          <h2 className="availability-title">1. Select Your Calendars</h2>
          <span className={`arrow ${showCalendars ? 'up' : 'down'}`}></span>
        </div>
        {showCalendars && (
          <div className="calendar-dropdown-list">
            {calendars.map((calendar) => (
              <div key={calendar.id} className="checkbox-wrapper-19">
                <input
                  type="checkbox"
                  id={`calendar-${calendar.id}`}
                  value={calendar.id}
                  checked={selectedCalendars.includes(calendar.id)}
                  onChange={handleCalendarChange}
                />
                <label htmlFor={`calendar-${calendar.id}`} className="check-box"></label>
                <span className="calendar-label">{calendar.summary}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Central container */}
      <div className="central-container">
        {/* Form Section */}
        <div className="section form-section">
          <h2 className="availability-title">2. Enter Your Availability Details</h2>
          <div className="content">
            <form>
              <label>
                Start Date:
                <input
                  type="date"
                  name="startDate"
                  onChange={handleChange}
                  value={formData.startDate}
                />
              </label>
              <label>
                End Date:
                <input
                  type="date"
                  name="endDate"
                  onChange={handleChange}
                  value={formData.endDate}
                />
              </label>
              <label>
                Duration (min):
                <input
                  type="number"
                  name="duration"
                  onChange={handleChange}
                  value={formData.duration}
                />
              </label>
              <label>
                Nothing Before:
                <input
                  type="time"
                  name="nothingBefore"
                  onChange={handleChange}
                  value={formData.nothingBefore}
                />
              </label>
              <label>
                Nothing After:
                <input
                  type="time"
                  name="nothingAfter"
                  onChange={handleChange}
                  value={formData.nothingAfter}
                />
              </label>
              <label>
                Buffer Time (min):
                <input
                  type="number"
                  name="bufferTime"
                  onChange={handleChange}
                  value={formData.bufferTime}
                />
              </label>
              <label>
                Exclude All-Day Events:
                <div className="toggle-switch larger-toggle">
                  <input
                    type="checkbox"
                    checked={excludeAllDayEvents}
                    onChange={(e) => setExcludeAllDayEvents(e.target.checked)}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              {/* New Day Selection Buttons */}
              <label>
                Select Days:
                <div className="day-buttons-container">
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => (
                    <button
                      key={index}
                      type="button"
                      className={`day-button ${selectedDays.includes(day) ? 'selected' : ''}`}
                      onClick={() => {
                        if (selectedDays.includes(day)) {
                          // Remove day
                          setSelectedDays(selectedDays.filter((d) => d !== day));
                        } else {
                          // Add day
                          setSelectedDays([...selectedDays, day]);
                        }
                      }}
                    >
                      {day}
                    </button>
                  ))}
                </div>
              </label>
            </form>
          </div>
          <div className="button-container">
            <button className="main-button" type="button" onClick={getAvailability}>
              {availability ? 'Regenerate Availability' : 'Get Availability'}
            </button>
          </div>
        </div>

        {/* Output Section */}
        <div className="section output-section">
          <div className="output-header">
            <div className="availability-title">
              <h2>3. Your Availability</h2>
              <div className='large-toggle-container'>
                <div className="toggle-container">
                  <span className="toggle-label">Dates Included</span>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={includeDates}
                      onChange={(e) => setIncludeDates(e.target.checked)}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className="toggle-container">
                    <span className="toggle-label">Include Timezone</span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={includeTimezone}
                        onChange={(e) => setIncludeTimezone(e.target.checked)}
                      />
                      <span className="slider"></span>
                    </label>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            {loading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : availability ? (
                <>
                  {includeTimezone && (
                    <div>
                      Timezone: {availability.user_timezone}
                    </div>
                  )}
                  <ul className="availability-list">
                    {includeDates
                      ? availability.formatted_slots_with_dates.map((slot, index) => (
                          <li key={index}>{slot}</li>
                        ))
                      : availability.formatted_slots.map((slot, index) => (
                          <li key={index}>{slot}</li>
                        ))}
                  </ul> 
                </>
              ) : (
                <div className="placeholder-text">
                  Your availability will appear here after you click "Get Availability".
                </div>
            )}
          </div>
          <div className="button-container">
          {availability && (
              <CopyToClipboard text={textToCopy} onCopy={() => setCopied(true)}>
                <button className="main-button copy-button">
                  {copied ? 'Copied!' : 'Copy to Clipboard'}
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailabilityForm;
