// axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Adjust as needed
  withCredentials: true, // To send cookies
});

// Request interceptor to add headers
axiosInstance.interceptors.request.use(
  (config) => {
    const provider = localStorage.getItem('provider');
    const accessToken = localStorage.getItem('accessToken'); // For Outlook users
    if (provider === 'outlook' && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['X-Provider'] = 'outlook';
    } else if (provider === 'google') {
      config.headers['X-Provider'] = 'google';
      // Google tokens are handled via cookies, no need for Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Response interceptor to handle token refresh for Google only
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      const provider = localStorage.getItem('provider');
      if (provider === 'google') {
        originalRequest._retry = true;
        try {
          await axiosInstance.post('/refresh-token');
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure (e.g., redirect to login)
          return Promise.reject(refreshError);
        }
      } else {
        // For other providers, do not attempt token refresh
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
