// App.js

import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import Login from './components/Login';
import Logout from './components/Logout';
import AvailabilityForm from './components/AvailabilityForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/footer';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { FaCoffee } from 'react-icons/fa';
import { FaRegThumbsUp } from "react-icons/fa6";


function App() {
  const [user, setUser] = useState(false);
  const [provider, setProvider] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const { instance, accounts } = useMsal();

  // Retrieve provider from localStorage on initial load
  useEffect(() => {
    const storedProvider = localStorage.getItem('provider');
    const storedAccessToken = localStorage.getItem('accessToken');
    if (storedProvider) {
      setProvider(storedProvider);
      if (storedProvider === 'outlook' && storedAccessToken) {
        setAccessToken(storedAccessToken);
      }
    } else {
      setLoading(false); // Avoid infinite loading state
    }
  }, []);

  const handleLoginSuccess = ({ provider: loginProvider, codeResponse, loginResponse }) => {
    if (loginProvider === 'google') {
      axiosInstance
        .post('/exchange-code', { code: codeResponse.code, provider: 'google' })
        .then(() => {
          setUser(true);
          setProvider('google');
          localStorage.setItem('provider', 'google'); // Store provider in localStorage
        })
        .catch((error) => {
          console.error('Error exchanging code:', error);
        });
      } else if (loginProvider === 'outlook') {
        console.log('Outlook Login Response:', loginResponse);
        const token = loginResponse.accessToken;
        if (!token) {
          console.error('No access token received from Outlook login.');
          return;
        }
        setAccessToken(token); // Store access token in state
        setUser(true);
        setProvider('outlook');
        localStorage.setItem('provider', 'outlook'); // Store provider in localStorage
        localStorage.setItem('accessToken', token); // Store access token in localStorage
      }
  };

  const handleLogout = () => {
    if (provider === 'outlook') {
      instance.logoutPopup().catch((error) => {
        console.error('Error logging out from Outlook:', error);
      });
    }
  
    axiosInstance
      .post('/logout')
      .then(() => {
        setUser(false);
        setProvider(null);
        setAccessToken(null); // Clear access token
        localStorage.removeItem('provider'); // Remove provider from localStorage
        localStorage.removeItem('accessToken'); // Remove access token from localStorage
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };
  

  useEffect(() => {
    if (provider) {
      // Check authentication status
      axiosInstance
        .get('/auth-status', {
          headers: {
            Authorization: provider === 'outlook' && accessToken ? `Bearer ${accessToken}` : '',
            'X-Provider': provider,
          },
        })
        .then((response) => {
          setUser(response.data.authenticated);
          setProvider(response.data.provider);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error checking authentication status:', error);
          setUser(false);
          setProvider(null);
          setLoading(false);
        });
    } else {
      setLoading(false); // Avoid infinite loading state
    }
  }, [accessToken, provider]);

  useEffect(() => {
    if (provider === 'outlook' && accounts.length > 0) {
      const account = accounts[0];
      instance
        .acquireTokenSilent({
          scopes: [
            'https://graph.microsoft.com/User.Read',
            'https://graph.microsoft.com/Calendars.Read',
            'https://graph.microsoft.com/MailboxSettings.Read',
          ],
          account: account,
        })
        .then((tokenResponse) => {
          setAccessToken(tokenResponse.accessToken);
          localStorage.setItem('accessToken', tokenResponse.accessToken); // Store access token in localStorage
        })
        .catch((error) => {
          console.error('Token acquisition failed:', error);
          // Optionally handle token acquisition errors here
        });
    }
  }, [accounts, provider, instance]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              {user ? (
                <div className="FIXED">
                  <div className="altered-top-banner">
                    <h1 className="app-title">Get My Availability</h1>
                    <div className="logout-container">
                      <Logout onLogout={handleLogout} />
                    </div>
                  </div>
                  {provider && (
                    <div className="provider-info-container">
                      <div className="provider-info">
                        <p>Logged in with {provider === 'google' ? 'Google' : 'Outlook'}</p>
                      </div>
                    </div>
                  )}
                  <div className="logged-in-content-container content-container">
                    <AvailabilityForm accessToken={accessToken} provider={provider} />
                  </div>
                  <div className="support-container">
                    <button
                      className="main-button"
                      onClick={() =>
                        window.open('https://buymeacoffee.com/getmyavailability', '_blank')
                      }
                    >
                      Support Small Developers
                      <FaCoffee className="button-icon"/>
                    </button>
                  </div>
                  <div className="support-container-two">
                    <button
                      className="main-button"
                      onClick={() =>
                        window.open(
                          'https://docs.google.com/forms/d/e/1FAIpQLSfWQtbBFmQrA3oetrsyFds2lLpB3Rdnw0h_9IJt6OwD3BsrMQ/viewform',
                          '_blank'
                        )
                      }
                    >
                      Feedback Form
                      <FaRegThumbsUp className="button-icon"/>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="real-app-title-container">
                    <h1 className="real-app-title">Get My Availability</h1>
                  </div>
                  <div className="content-container">
                    <Login onLoginSuccess={handleLoginSuccess} />
                  </div>
                </>
              )}
              <Footer />
            </div>
          }
        />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
