// src/components/Login.js

import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import './Login.css';

function Login({ onLoginSuccess }) {
  const { instance } = useMsal();

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    access_type: 'offline',
    prompt: 'consent',
    onSuccess: (codeResponse) => {
      console.log('Google Code Response:', codeResponse);
      onLoginSuccess({ provider: 'google', codeResponse });
    },
    onError: () => {
      console.log('Google Login Failed');
    },
  });

  const handleOutlookLogin = () => {
    instance
      .loginPopup({
        scopes: [
          'https://graph.microsoft.com/User.Read',
          'https://graph.microsoft.com/Calendars.Read',
          'https://graph.microsoft.com/MailboxSettings.Read',
        ],
        prompt: 'select_account',
      })
      .then((loginResponse) => {
        return instance.acquireTokenSilent({
          scopes: [
            'https://graph.microsoft.com/User.Read',
            'https://graph.microsoft.com/Calendars.Read',
            'https://graph.microsoft.com/MailboxSettings.Read',
          ],
          account: loginResponse.account,
        });
      })
      .then((tokenResponse) => {
        console.log('Outlook Token Response:', tokenResponse);
        onLoginSuccess({ provider: 'outlook', loginResponse: tokenResponse });
      })
      .catch((error) => {
        console.log('Outlook Login Failed:', error);
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup({
              scopes: [
                'https://graph.microsoft.com/User.Read',
                'https://graph.microsoft.com/Calendars.Read',
                'https://graph.microsoft.com/MailboxSettings.Read',
              ],
            })
            .then((tokenResponse) => {
              console.log('Outlook Token Response:', tokenResponse);
              onLoginSuccess({ provider: 'outlook', loginResponse: tokenResponse });
            })
            .catch((error) => {
              console.log('Token acquisition failed:', error);
            });
        }
      });
  };

  return (
    <div className="login-main-container">
      <h2 className="paragraph-one-text">
        Connect your calendar and effortlessly share your availability - no booking links required
      </h2>
      <h3 className="sub-paragraph-one-text">
        Perfect for coffee chats, client meetings, group projects, interviews, and more.
      </h3>

      {/* Google Sign-In Button */}
      <button className="gsi-material-button" onClick={() => login()}>
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            {/* Google Logo SVG */}
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{ display: 'block' }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9
                2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56
                13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58
                2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18
                7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92
                16.46 0 20.12 0 24c0 3.88.92 7.54 2.56
                10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15
                1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98
                6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="gsi-material-button-contents">Sign in with Google</span>
          <span style={{ display: 'none' }}>Sign in with Google</span>
        </div>
      </button>

      <button className="microsoft-button" onClick={handleOutlookLogin}>
        <div className="microsoft-button-content-wrapper">
          <img
            src="/windows_logo.svg"
            alt="Microsoft Logo"
            className="microsoft-button-icon"
          />
          <span className="microsoft-button-text">Sign in with Microsoft</span>
        </div>
      </button>

      <h3 className="note"><strong>Note:</strong> If you see "Google hasn’t verified this app", click <strong>"Advanced"</strong> and then <strong>"Go to Get My Availability"</strong> to continue</h3>

      {/* Substack Iframe */}
      <div className="iframe-container">
        <iframe
          src="https://getmyavailability.substack.com/embed"
          width="100%"
          height="320"
          style={{ border: '1px solid #EEE', background: 'white' }}
          title="Newsletter Subscription"
        ></iframe>
      </div>
    </div>
  );
}

export default Login;
