// src/components/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Add styles for this page if necessary
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <div className="privacy-container">
      <h1 className="app-title">Get My Availability</h1>
      <div className="privacy-content">
        <h2>Privacy Policy</h2>
        <p>Effective Date: October 24, 2024</p>
        <p>
          <strong>1. Introduction</strong>
          <br />
          Welcome to Get My Availability, where we strive to make sharing your calendar availability as easy as possible. Your privacy is of utmost importance to us, and we are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website, getmyavailability.com (the “Site”).
        </p>
        <p>
          <strong>2. Information We Collect</strong>
          <br />
          To provide you with our services effectively, we collect several types of information:
        </p>
        <p>
          <strong>Personal Data:</strong> When you sign in using Google’s OAuth API, we gather your email address and calendar data. This information is essential for allowing you to use our services seamlessly.
        </p>
        <p>
          <strong>Cookies:</strong> Our Site may use cookies and similar tracking technologies to enhance your browsing experience. Cookies are small data files stored on your device, helping us recognize your preferences and improve our services.
        </p>
        <p>
          <strong>3. How We Use Your Information</strong>
          <br />
          We use the information collected for various purposes, including but not limited to:
        </p>
        <ul>
          <li>Service Delivery: To provide, maintain, and improve our Service, ensuring a smooth experience for you.</li>
          <li>Communication: To notify you about changes to our Service, respond to your inquiries, and provide customer support.</li>
          <li>Personalization: To tailor your experience based on your preferences and usage patterns.</li>
        </ul>
        <p>
          <strong>4. Data Storage and Security</strong>
          <br />
          Your data is securely stored using reputable cloud services that adhere to industry standards for data protection. We implement appropriate technical and organizational measures to protect your information against unauthorized access, loss, or misuse.
        </p>
        <p>
          <strong>5. Sharing Your Information</strong>
          <br />
          At Get My Availability, we respect your privacy. We do not sell or rent your personal information to third parties. However, we may share your data with trusted partners who assist us in operating our Site and delivering our services. These partners are bound by strict confidentiality obligations and are only permitted to use your data as necessary to provide their services.
        </p>
        <p>
          <strong>6. User Rights and Choices</strong>
          <br />
          You have several rights regarding your personal data:
        </p>
        <ul>
          <li>Access: You can request access to the personal data we hold about you.</li>
          <li>Correction: If you believe any information we hold is inaccurate or incomplete, you can request corrections.</li>
          <li>Deletion: You may ask us to delete your personal data, subject to certain legal obligations.</li>
          <li>Opt-Out: You can opt out of receiving marketing communications from us at any time.</li>
        </ul>
        <p>
          <strong>7. Cookies and Tracking Technologies</strong>
          <br />
          Cookies are utilized on our Site to enhance your experience and analyze website traffic. You can manage cookie preferences through your web browser settings. While cookies help us provide a better experience, you can choose to disable them; however, this may affect the functionality of our Site.
        </p>
        <p>
          <strong>8. Changes to This Privacy Policy</strong>
          <br />
          We reserve the right to update this Privacy Policy at any time. When we do, we will notify you via email or by posting the updated policy on our Site. We encourage you to periodically review this Privacy Policy for any changes. Your continued use of the Site after any modifications indicates your acceptance of the new terms.
        </p>
        <p>
          <strong>9. Contact Us</strong>
          <br />
          If you have any questions or concerns about this Privacy Policy or our practices, please reach out to us: Email: getmyavailability@gmail.com
        </p>
        <p>Thank you for trusting Get My Availability to handle your data. We value your privacy and appreciate your support as we work to make your scheduling experiences easier and more convenient.</p>
      </div>
      <div className="back-button-container">
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
