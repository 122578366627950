// src/components/Footer.js
import React from 'react';
import './footer.css';

function Footer() {
  return (
    <div className="footer">
      <a href="https://buymeacoffee.com/getmyavailability" className="footer-link" id="support-link">
        Support Us
      </a>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWQtbBFmQrA3oetrsyFds2lLpB3Rdnw0h_9IJt6OwD3BsrMQ/viewform" className="footer-link" id="feedback-link">
        Feedback Form
      </a>
      <a href="/privacypolicy" className="footer-link" id="privacy-link">
        Privacy Policy
      </a>
      <a href="https://getmyavailability.substack.com/embed" className="footer-link" id="privacy-link">
        Newsletter
      </a>
    </div>
  );
}

export default Footer;
