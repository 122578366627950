import React from 'react';
import './Logout.css'; // Import the CSS file

function Logout({ onLogout }) {
  return (
    <button className="logout-button" onClick={onLogout}>
      Logout
    </button>
  );
}

export default Logout;
